import axios from "axios";

const instance = axios.create({
 //baseURL: "http://localhost:8000/",
 baseURL: "https://sap-backend.ddns.net/"
  
  
});

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
